<template>
  <div class="property-change-status card p-4">
    <div class="ref-code">{{ property.referenceCode }}</div>
    <fd-form-section title="Change Status">
      <fd-select
        v-model="status"
        class="col-12"
        label="Select New Status"
        :options="options"
        required
      >
      </fd-select>
    </fd-form-section>
    <div class="text-right">
      <button class="btn mr-1" @click="$emit('close')">Cancel</button>
      <button
        class="btn main"
        @click="$emit('update', { id: property.id, status: status })"
      >
        Update
      </button>
    </div>
  </div>
</template>

<script>
import propertyStatusEnum from "@/enums/propertyStatus";

export default {
  components: {},
  mixins: [],
  props: {
    property: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      status: "",
      propertyStatusEnum,
      options: []
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initOptions();
    this.initStatus();
  },
  methods: {
    initOptions() {
      for (let status in propertyStatusEnum) {
        this.options.push({
          id: propertyStatusEnum[status],
          name: propertyStatusEnum[status]
        });
      }
    },
    initStatus() {
      this.status = this.property.status;
    }
  }
};
</script>

<style lang="scss">
.ref-code {
  margin-bottom: 12px;
  border: solid 1px $color-secondary;
  width: fit-content;
  border-radius: 5px;
  padding: 6px 15px;
  color: $color-secondary;
}
</style>
